import React from 'react'
import Layout from '../components/layout'
import Container from '../components/container'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>Under Construction</h1>
      <p>Sedang diproses. Sebentaaar lagi :)</p>
    </Container>
  </Layout>
)

export default NotFoundPage
